import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useState } from 'react';
import { SimpleCard } from 'app/components';

import { Stack } from '@mui/material';
import AdminForm from './AdminForm';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const AdminDialog = ({ fetchAdminDetails, admin }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const [adminName, setAdminName] = useState(admin?.adminName);
  const [email, setEmail] = useState(admin?.email);
  const [profileImage, setProfileImage] = useState(admin?.adminProfile);

  const handleClose = () => {
    fetchAdminDetails();
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        View Details
      </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Admin Details
        </DialogTitle>
        <Container>
          <Stack spacing={3}>
            <SimpleCard>
              <AdminForm
                setAdminName={setAdminName}
                adminName={adminName}
                email={email}
                setEmail={setEmail}
                setProfileImage={setProfileImage}
                profileImage={profileImage}
                handleCloseDialog={handleClose}
                admin={admin}
              />
            </SimpleCard>
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
};

export default AdminDialog;
