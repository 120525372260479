import { Analytics } from '@mui/icons-material';
import AdminDashboard from './AdminDashboard';
import AdminList from '../admin/AdminList';
import { authRoles } from 'app/auth/authRoles';
import AgencyDashboard from './AgencyDashboard';
import AgencyList from '../agency/AgencyList';
import NewsList from '../news/NewsList';
import ContactList from '../contact/ContactList';
import AdsList from '../ads/AdsList';

const dashboardRoutes = [
  { path: '/dashboard/admin', element: <AdminDashboard />, auth: authRoles.admin },
  { path: '/dashboard/agency', element: <AgencyDashboard />, auth: authRoles.agency },
  { path: '/admin/adminList', element: <AdminList />, auth: authRoles.admin },
  { path: '/admin/agencyList', element: <AgencyList />, auth: authRoles.admin },
  { path: '/agency/newsList', element: <NewsList />, auth: authRoles.agency },
  { path: '/admin/adsList', element: <AdsList />, auth: authRoles.admin },
  { path: '/admin/contact', element: <ContactList />, auth: authRoles.admin }
];

export default dashboardRoutes;
