import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAdmin = (adminRequest) => {
  const urlPath = '/admin/addAdmin';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: adminRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const updateAdminDetails = (adminRequest) => {
  const urlPath = '/admin/updateAdmin';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: adminRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getAllAdminDetails = () => {
  console.log('inadmin');
  const urlPath = '/admin/getAllAdminDetails';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
    // Authorization: `Bearer ${authToken}`,
  });
};
export const getSelectedAdminDetails = (adminId) => {
  const urlPath = `/admin/getadminDetails/${adminId}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
    // Authorization: `Bearer ${authToken}`,
  });
};
