import React, { useState } from 'react';
import {
    Box,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    useMediaQuery,
    useTheme
} from '@mui/material';
import DeleteContact from './DeleteContact';
import ContactDialog from './ContactDialog';

const StyledTable = styled(Table)({
    whiteSpace: 'pre',
    minWidth: 600,
    overflowX: 'auto'
});

const ContactTable = ({ deleteContactDetails, fetchContactDetails, contactList }) => {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box width="100%" overflow="auto">
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Name</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Email</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>View</TableCell>
                        <TableCell align={isXsScreen ? 'left' : 'center'}>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contactList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((contact, index) => (
                            <TableRow
                                key={index}
                                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
                            >
                                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                                <TableCell align={isXsScreen ? 'left' : 'center'}>
                                    {contact?.contactName.replace(/^\w/, (c) => c.toUpperCase())}
                                </TableCell>
                                <TableCell align={isXsScreen ? 'left' : 'center'}>{contact?.email}</TableCell>
                                <TableCell align={isXsScreen ? 'left' : 'center'}>
                                    <ContactDialog fetchContactDetails={fetchContactDetails} contact={contact} />
                                </TableCell>
                                <TableCell align={isXsScreen ? 'left' : 'center'}>
                                    <DeleteContact
                                        deleteContactDetails={deleteContactDetails}
                                        fetchContactDetails={fetchContactDetails}
                                        contact={contact}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </StyledTable>

            <TablePagination
                sx={{ px: 2 }}
                page={page}
                component="div"
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={'Records per page'}
                count={contactList.length}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            />
        </Box>
    );
};

export default ContactTable;
