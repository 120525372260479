import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import agreementJson from "./terms.json";
import loginImg from '../../../logo.svg'; // Adjust the path according to your project structure

const TermsPage = () => {
  const { agreement } = agreementJson;
  const { heading, sections } = agreement;

  const paperStyle = {
    width: "auto",
    height: "297mm",
    border: "1px solid #000",
    backgroundColor: "#e9e9e9",
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={12} style={{ textAlign: 'center', padding: '20px' }}>
        <img src={loginImg} alt="Login" style={{ width: '150px', borderRadius: '10px' }} />
      </Grid>

      {/* Paper in the middle */}
      <Grid item xs={8} style={{ padding: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions For Publishers
        </Typography>
        <div style={paperStyle}>
          <Grid style={{ padding: "20px" }}>
            <Typography variant="body1">{heading}</Typography>
            {sections &&
              sections.map((section) => (
                <div key={section.sectionNumber}>
                  <Typography variant="h6" gutterBottom>
                    {section.title}
                  </Typography>
                  <Typography variant="body1">{section.content}</Typography>
                  <br />
                </div>
              ))}
          </Grid>
        </div>
      </Grid>

      {/* White space on the right */}
      <Grid item xs={2}></Grid>
    </Grid>
  );
};

export default TermsPage;
