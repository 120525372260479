import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addNews = (newsRequest) => {
  const urlPath = '/news/addNews';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getAllNewsDetails = () => {
  const urlPath = '/news/getAllNewsDetails';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const updateNewsDetails = (newsRequest) => {
  const urlPath = '/news/updateNews';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: newsRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const removeNewsDetails = (newsId) => {
  const urlPath = `/news/removeNews/${newsId}`;
  return API.request({
    method: HttpMethod.PUT,
    urlPath: urlPath
  });
};
export const getNewsByAgencyId = (agencyId) => {
  const urlPath = `/news/getNewsByAgencyId/${agencyId}`;
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};
export const uploadNewsImages = (uploadRequest) => {
  const urlPath = '/news/uploadNewsImages';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: uploadRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};