import axios from 'axios';
import { getBaseUrl } from './network';
// import { getPlatformVersion, getAccessToken, getUserId } from '../preferences';
export const API = class API {
  static request = async ({ method, urlPath, body, additionalHeader }) => {
    return new Promise(async (resolve, reject) => {
      axios({
        method: method,
        baseURL: await getBaseUrl(),
        headers: {
          ...additionalHeader
          // PLATFORM_VERSION: getPlatformVersion(),
          // 'Access-Token': await getAccessToken(),
          // 'User-ID': await getUserId(),
        },
        url: urlPath,
        data: body
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  static requestWithoutHeaders = async ({ method, urlPath, body }) => {
    return new Promise(async (resolve, reject) => {
      axios({
        method: method,
        baseURL: await getBaseUrl(),
        url: urlPath,
        data: body
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
