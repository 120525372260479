import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import NewsDialog from './NewsDialog';
import DeleteNews from './DeleteNews';
import moment from 'moment';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600,
  overflowX: 'auto'
});

const NewsTable = ({ fetchNewsDetails, deleteNewsDetails, newsList }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Title</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>News Type</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>News For</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>News Added Date</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>News Details</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Delete News</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newsList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((news, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
              >
                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <strong> {news?.title?.replace(/^\w/, (c) => c.toUpperCase())}
                  </strong>
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {news?.newsType?.[0]?.label}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {news?.newsDomain}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>{moment(news?.createdDate).format('DD-MM-YYYY')}</TableCell>

                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <NewsDialog fetchNewsDetails={fetchNewsDetails} news={news} />
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <DeleteNews
                    deleteNewsDetails={deleteNewsDetails}
                    fetchNewsDetails={fetchNewsDetails}
                    news={news}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={newsList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default NewsTable;
