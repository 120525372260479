import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAgency = (agencyRequest) => {
  const urlPath = '/agency/addAgency';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: agencyRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const updateAgencyDetails = (agencyRequest) => {
  const urlPath = '/agency/updateAgency';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: agencyRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getAllAgencyDetails = () => {
  const urlPath = '/agency/getAllAgencyDetails';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const getAgencyById = () => {
  const urlPath = '/agency/getAgencyDetails/:id';
  return API.request({
    method: HttpMethod.GET,
    urlPath: urlPath
  });
};

export const removeAgencyById = (agencyId) => {
  const urlPath = `/agency/removeAgencyDetails/${agencyId}`;
  return API.request({
    method: HttpMethod.DELETE,
    urlPath: urlPath
  });
};

export const uploadAgencyImages = (uploadRequest) => {
  const urlPath = '/agency/uploadAgencyImages';
  return API.request({
    method: HttpMethod.POST,
    urlPath: urlPath,
    body: uploadRequest,
    additionalHeader: {
      "Content-Type": "multipart/form-data"
    }
  });
};
