import { LoadingButton } from '@mui/lab';
import { Alert, Card, Grid, Snackbar, TextField } from '@mui/material';
import { Box, styled, useTheme } from '@mui/system';
import { Paragraph } from 'app/components/Typography';
import useAuth from 'app/hooks/useAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import PasswordField from './PasswordField';
import { resetPassword } from 'app/api-ws/auth-ws';
import loginImg from '../../../logo.svg';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRoot = styled(JustifyBox)(() => ({
    background: '#1A2038',
    minHeight: '100% !important',
    '& .card': {
        maxWidth: 800,
        minHeight: 400,
        margin: '1rem',
        display: 'flex',
        borderRadius: 12,
        alignItems: 'center',
    },
}));

// inital login credentials
const initialValues = {
    email: '',
    password: '',
    confirmPassword: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(4, 'Password must be 6 character length')
        .required('Password is required!'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is required!'),
    email: Yup.string().email('Invalid Email address').required('Email is required!'),
});

const SetPassword = () => {
    const { token } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { login } = useAuth();
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarType, setSnackBarType] = useState('success');
    let resetPasswordRequest = {};

    const handleFormSubmit = async (values) => {
        resetPasswordRequest = {
            resetToken: token,
            newPassword: values.password,
        };
        setLoading(true);
        resetPassword(resetPasswordRequest)
            .then((res) => {
                setLoading(false);
                setSnackBarMessage(res.data.message);
                setSnackBarType('success');
                showSnackBar();
                setTimeout(() => {
                    navigate('/session/signin');
                }, 3000);
            })
            .catch((err) => {
                setLoading(false);
                setSnackBarMessage(err.response.data.message);
                setSnackBarType('error');
                showSnackBar();
            });
    };

    function showSnackBar() {
        setOpen(true);
    }
    function handleClose(_, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    return (
        <JWTRoot>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={snackBarType}
                    sx={{ width: '100%' }}
                    variant="filled"
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Card className="card">
                <Grid container>
                    <Grid item sm={6} xs={12}>
                        <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
                            <img src={loginImg} width="100%" alt="" style={{ borderRadius: '10px' }} />
                        </JustifyBox>
                    </Grid>

                    <Grid item sm={6} xs={12} alignSelf={'center'}>
                        <ContentBox>
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            >
                                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="email"
                                            name="email"
                                            label="Email"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            value={values.email}
                                            onChange={handleChange}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(errors.email && touched.email)}
                                            sx={{ mb: 3 }}
                                        />
                                        <PasswordField
                                            label={"New Password"}
                                            touched={touched}
                                            values={values.password}
                                            handleChange={handleChange}
                                            helperText={touched.password && errors.password}
                                            errors={Boolean(errors.password && touched.password)}
                                            handleBlur={handleBlur}
                                        />
                                        <PasswordField
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            touched={touched}
                                            values={values.confirmPassword}
                                            handleChange={handleChange}
                                            helperText={touched.confirmPassword && errors.confirmPassword}
                                            errors={Boolean(errors.confirmPassword && touched.confirmPassword)}
                                            handleBlur={handleBlur}
                                        />

                                        <FlexBox justifyContent="space-between">
                                            <NavLink to="/session/signin" style={{ color: theme.palette.primary.main }}>
                                                Login Instead?
                                            </NavLink>
                                        </FlexBox>

                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            loading={loading}
                                            variant="contained"
                                            sx={{ my: 2 }}
                                        >
                                            Change Password
                                        </LoadingButton>
                                    </form>
                                )}
                            </Formik>
                        </ContentBox>
                    </Grid>
                </Grid>
            </Card>
        </JWTRoot>
    );
};

export default SetPassword;