export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

export const getBaseUrl = async () => {
  return 'https://ws.namnews.in/api/';
  // return 'http://localhost:3000/api/'
};
