import React, { useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Grid,
    Snackbar,
    styled,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
} from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import { SimpleCard } from 'app/components';
import { addAds, updateAdsDetails, uploadAdsImages } from 'app/api-ws/ads-ws';
import useAuth from 'app/hooks/useAuth';

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}));

const newsTypeOptions = [
    { value: 'Celebration', label: 'Celebration' },
    { value: 'Political', label: 'Political' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Business', label: 'Business' },
];

const imageTypeOptions = [
    { value: 'Banner', label: 'Banner' },
    { value: 'Portrait', label: 'Portrait' },
];

const regionTypeOptions = [
    { value: "All", label: "All" },
    { value: "Bagalkot", label: "Bagalkot" },
    { value: "Ballari", label: "Ballari" },
    { value: "Belagavi", label: "Belagavi" },
    { value: "Bengaluru Urban", label: "Bengaluru Urban" },
    { value: "Bengaluru Rural", label: "Bengaluru Rural" },
    { value: "Bidar", label: "Bidar" },
    { value: "Chamarajanagar", label: "Chamarajanagar" },
    { value: "Chikkaballapur", label: "Chikkaballapur" },
    { value: "Chikkamagaluru", label: "Chikkamagaluru" },
    { value: "Chitradurga", label: "Chitradurga" },
    { value: "Dakshina Kannada", label: "Dakshina Kannada" },
    { value: "Davanagere", label: "Davanagere" },
    { value: "Dharwad", label: "Dharwad" },
    { value: "Gadag", label: "Gadag" },
    { value: "Hassan", label: "Hassan" },
    { value: "Haveri", label: "Haveri" },
    { value: "Kalaburagi", label: "Kalaburagi" },
    { value: "Kodagu", label: "Kodagu" },
    { value: "Kolar", label: "Kolar" },
    { value: "Koppal", label: "Koppal" },
    { value: "Mandya", label: "Mandya" },
    { value: "Mysuru", label: "Mysuru" },
    { value: "Raichur", label: "Raichur" },
    { value: "Ramanagara", label: "Ramanagara" },
    { value: "Shivamogga", label: "Shivamogga" },
    { value: "Tumakuru", label: "Tumakuru" },
    { value: "Udupi", label: "Udupi" },
    { value: "Uttara Kannada", label: "Uttara Kannada" },
    { value: "Vijayapura", label: "Vijayapura" },
    { value: "Vijayanagara", "label": "Vijayanagara" },
    { value: "Yadgir", label: "Yadgir" }
];

const MAX_FILE_SIZE = 5000000; // 5MB

const AdsForm = ({ ad, handleCloseDialog }) => {
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [adCaption, setAdCaption] = useState(ad?.adCaption || '');
    const [adGenre, setAdGenre] = useState(ad?.adGenre || '');
    const [imageType, setImageType] = useState(ad?.imageType || '');
    const [adsImage, setAdsImage] = useState(ad?.adsImage || []);
    const [targetAudience, setTargetAudience] = useState(ad?.targetAudience || []);
    const [refAgencyId, setRefAgencyId] = useState(ad?.refAgencyId || user?._id);
    const [refAgencyName, setRefAgencyName] = useState(ad?.refAgencyName || user?.agencyName);
    const [adsUrl, setAdsUrl] = useState(ad?.adsUrl || '');
    const [startDate, setStartDate] = useState(moment(ad?.startDate).format('YYYY-MM-DD'));
    const [expiryDate, setExpiryDate] = useState(moment(ad?.expiryDate).format('YYYY-MM-DD'));
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarType, setSnackBarType] = useState('success');
    const [images, setImages] = useState([]);
    // const [uploadImagesRequest, setUploadImagesRequest] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [previews, setPreviews] = useState([]);
    const [isImageUpdated, setIsImageUpdated] = useState(false);

    const handleImageChange = (event) => {
        setError(null);
        const files = Array.from(event.target.files);

        const maxFileSize = 1 * 1024 * 1024; // 2MB

        if (files && files.length > 0) {
            // const imageArray = [];

            for (let i = 0; i < files.length; i++) {
                const currentFile = files[i];

                if (currentFile.size > maxFileSize) {
                    setError(`File size exceeds 1MB: ${currentFile.name}`);
                    return;
                }
            }
            setImages(files);

            const previewFiles = files.map(file => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                return new Promise(resolve => {
                    reader.onload = () => resolve(reader.result);
                });
            });

            Promise.all(previewFiles).then(previewImages => {
                setPreviews(previewImages);
            });
        }
        setIsImageUpdated(true);
    };

    const parseDataUrl = (dataUrl, originalFilename) => {
        const matches = dataUrl.match(/^data:(.*?);base64,(.*)$/);
        if (!matches || matches.length !== 3) {
            throw new Error('Invalid Data URL format');
        }
        const [, type, data] = matches;
        const filename = originalFilename || `image.${type.split('/')[1]}`;
        return { filename, type, data };
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        try {
            let adId = ad?._id;

            const formData = new FormData();
            if (adId) {
                formData.append('_id', adId);
                formData.append('isImageUpdated', isImageUpdated);
            }
            formData.append('adCaption', adCaption);
            formData.append('adGenre', adGenre);
            formData.append('adsUrl', adsUrl);
            formData.append('imageType', imageType);
            formData.append('adsImage', images[0]);
            formData.append('refAgencyId', refAgencyId);
            formData.append('refAgencyName', refAgencyName);
            formData.append('startDate', startDate);
            formData.append('expiryDate', expiryDate);
            formData.append('targetAudience', targetAudience);
            const res = ad ? await updateAdsDetails(formData) : await addAds(formData);
            if (res.data.status === 'success') {
                setSnackBarMessage('Ad Saved Successfully');
                setSnackBarType('success');
                adId = res.data.data._id;
            } else {
                setSnackBarMessage('Error in Saving Ad');
                setSnackBarType('error');
            }
        } catch (err) {
            console.error(err);
            setSnackBarMessage('Error in Saving Ad');
            setSnackBarType('error');
        } finally {
            handleCloseDialog();
            setOpen(true);
            setLoading(false);
        }
    };

    const handleTargetAudienceChange = (event) => {
        setTargetAudience(event.target.value);
    };

    const handleCloseSnackbar = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }} variant="filled">
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <SimpleCard>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {adsImage?.length > 0 && (
                        <div>
                            <h3>Ad Images</h3>
                            <Grid container spacing={2}>
                                {adsImage.map((image, index) => (
                                    <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                                        <img
                                            src={image?.url ?? image}
                                            alt={`Ad Image ${index + 1}`}
                                            style={{ maxWidth: '100%', maxHeight: '300px', marginBottom: '10px' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                    {previews.length > 0 && (
                        <div>
                            <h3>Preview:</h3>
                            <Grid container spacing={2}>
                                {previews.map((preview, index) => (
                                    <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                                        <img
                                            src={preview}
                                            alt={`Uploaded ${index + 1}`}
                                            style={{ maxWidth: '100%', maxHeight: '300px', marginBottom: '10px' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        required
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
                            Upload Image
                        </Button>
                    </label>
                </div>
            </SimpleCard>
            <Box py="12px" />
            <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            name="caption"
                            value={adCaption}
                            onChange={(val) => setAdCaption(val.target.value)}
                            label="Ad Caption"
                            validators={['required']}
                            errorMessages={['Required! Please fill out this field.']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="ad-genre-label">Ad Genre</InputLabel>
                            <Select
                                labelId="ad-genre-label"
                                id="adGenre"
                                name="adGenre"
                                value={adGenre}
                                onChange={(val) => setAdGenre(val.target.value)}
                                label="Ad Genre"
                            >
                                {newsTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="targetAudience-genre-label">Target Audience</InputLabel>
                            <Select
                                labelId="targetAudience-genre-label"
                                id="targetAudience"
                                name="targetAudience"
                                value={targetAudience}
                                onChange={handleTargetAudienceChange}
                                label="Target Audience"
                                multiple
                            >
                                {regionTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth required>
                            <InputLabel id="image-type-label">Image Type</InputLabel>
                            <Select
                                labelId="image-type-label"
                                id="imageType"
                                name="imageType"
                                value={imageType}
                                onChange={(val) => setImageType(val.target.value)}
                                label="Image Type"
                                required="true"
                            >
                                {imageTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="date"
                            name="startDate"
                            value={startDate}
                            onChange={(val) => setStartDate(val.target.value)}
                            validators={['required']}
                            errorMessages={['Required! Please select a start date.']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="date"
                            name="expiryDate"
                            value={expiryDate}
                            onChange={(val) => setExpiryDate(val.target.value)}
                            validators={['required']}
                            errorMessages={['Required! Please select an expiry date.']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            name="refAgencyName"
                            value={refAgencyName}
                            onChange={(val) => setRefAgencyName(val.target.value)}
                            label="Agency Name"
                            validators={['required']}
                            errorMessages={['Required! Please fill out this field.']}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="text"
                            name="adsUrl"
                            value={adsUrl}
                            onChange={(val) => setAdsUrl((val.target.value).trim())}
                            label="Ads Url/Link"
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button color="primary" variant="contained" type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : ad ? 'Update Ad' : 'Add Ad'}
                    </Button>
                </Box>
            </ValidatorForm>
        </div>
    );
};

export default AdsForm;
