import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';

export const addAds = (adsRequest) => {
    const urlPath = '/ads/addAds';
    return API.request({
        method: HttpMethod.POST,
        urlPath: urlPath,
        body: adsRequest,
        additionalHeader: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const getAllAdsDetails = () => {
    const urlPath = '/ads/getAllAds';
    return API.request({
        method: HttpMethod.GET,
        urlPath: urlPath
    });
};

export const updateAdsDetails = (adsRequest) => {
    const urlPath = '/ads/updateAds';
    return API.request({
        method: HttpMethod.POST,
        urlPath: urlPath,
        body: adsRequest,
        additionalHeader: {
            "Content-Type": "multipart/form-data"
        }
    });
};

export const deleteAds = (adsId) => {
    const urlPath = `/ads/removeAds/${adsId}`;
    return API.request({
        method: HttpMethod.DELETE,
        urlPath: urlPath
    });
};

export const getAdsById = (adsId) => {
    const urlPath = `/ads/getAdsById/${adsId}`;
    return API.request({
        method: HttpMethod.GET,
        urlPath: urlPath
    });
};

export const uploadAdsImages = (uploadRequest) => {
    const urlPath = '/ads/uploadAdsImages';
    return API.request({
        method: HttpMethod.POST,
        urlPath: urlPath,
        body: uploadRequest
    });
};

