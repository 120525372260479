import { SimpleCard } from 'app/components';
import {
    Button,
    DialogTitle,
    DialogContent,
    Icon,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
    Grid,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { getAllContactDetails, removeContactById } from 'app/api-ws/contact-ws';
import ContactTable from './ContactTable';

function ContactList() {
    const [contactList, setContactList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarType, setSnackBarType] = useState('success');
    const [openDialog, setOpenDialog] = useState(false);

    function showSnackBar() {
        setOpen(true);
    }
    function handleClose(_, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }
    const handleCloseDialog = () => {
        fetchContactDetails();
        setOpenDialog(false);
    };

    useEffect(() => {
        fetchContactDetails();
    }, []);

    const deleteContactDetails = (contactId) => {
        removeContactById(contactId)
            .then((res) => {
                fetchContactDetails();
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(true);
                setSnackBarMessage('Error in Removing contact');
                setSnackBarType('error', err);
                showSnackBar();
            });
    };
    const fetchContactDetails = () => {
        console.log('fetch');
        getAllContactDetails()
            .then((res) => {
                setContactList(res.data.data.reverse());
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setSnackBarMessage('Error in fetching contact');
                setSnackBarType('error', err);
                showSnackBar();
            });
    };
    const StyledButton = styled(Button)(({ theme }) => ({
        margin: theme.spacing(1)
    }));
    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: { margin: '16px' },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
        }
    }));

    if (isLoading) {
        return (
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={snackBarType}
                    sx={{ width: '100%' }}
                    variant="filled"
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Container>

                <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                    <DialogTitle>Contact Form</DialogTitle>
                    <DialogContent>
                        <Grid>
                            <Typography></Typography>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <SimpleCard title="contact DETAILS">
                    <ContactTable
                        deleteContactDetails={deleteContactDetails}
                        fetchContactDetails={fetchContactDetails}
                        contactList={contactList}
                    />
                </SimpleCard>
            </Container>
        </div>
    );
};


export default ContactList




