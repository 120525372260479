// AdsDialog.jsx
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import { SimpleCard } from 'app/components';
import { Stack } from '@mui/material';
import AdsForm from './AdsForm';
import moment from 'moment';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const AdsDialog = ({ fetchAdsDetails, ads }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // console.log("Dialog opened");
    // console.log({ ads }); // Check if ads contains the expected data
  };

  const [adCaption, setAdCaption] = useState('');
  const [adsUrl, setAdsUrl] = useState('');
  const [adGenre, setAdGenre] = useState('');
  const [imageType, setImageType] = useState('');
  const [adsImage, setAdsImage] = useState([]);
  const [targetAudience, setTargetAudience] = useState([]);
  const [refAgencyId, setRefAgencyId] = useState('');
  const [refAgencyName, setRefAgencyName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  useEffect(() => {
    if (ads) {
      setAdCaption(ads.adCaption);
      setAdsUrl(ads.adsUrl);
      setAdGenre(ads.adGenre);
      setImageType(ads.imageType);
      setAdsImage(ads.adsImage);
      setTargetAudience(ads.targetAudience);
      setRefAgencyId(ads.refAgencyId);
      setRefAgencyName(ads.refAgencyName);
      setStartDate(moment(ads.startDate).format('YYYY-MM-DD'));
      setExpiryDate(moment(ads.expiryDate).format('YYYY-MM-DD'));
      console.log("Ads state set with new ads data");
    }
  }, [ads]);

  const handleClose = () => {
    fetchAdsDetails();
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        View Details
      </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Advertisement Details
        </DialogTitle>
        <Container>
          <Stack spacing={3}>
            <SimpleCard>
              <AdsForm
                setAdCaption={setAdCaption}
                adCaption={adCaption}
                adsUrl={adsUrl}
                setAdsUrl={setAdsUrl}
                setAdGenre={setAdGenre}
                adGenre={adGenre}
                setImageType={setImageType}
                imageType={imageType}
                setAdsImage={setAdsImage}
                adsImage={adsImage}
                setTargetAudience={setTargetAudience}
                targetAudience={targetAudience}
                setRefAgencyId={setRefAgencyId}
                refAgencyId={refAgencyId}
                setRefAgencyName={setRefAgencyName}
                refAgencyName={refAgencyName}
                setStartDate={setStartDate}
                startDate={startDate}
                setExpiryDate={setExpiryDate}
                expiryDate={expiryDate}
                handleCloseDialog={handleClose}
                ad={ads}
              />
            </SimpleCard>
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
};

export default AdsDialog
