import React, { useState } from 'react';
import useAuth from 'app/hooks/useAuth';
import AdminProfile from './adminProfile';
import NotFound from '../sessions/NotFound';
import { Alert, Snackbar } from '@mui/material';
import { getAgencyById } from 'app/api-ws/agency-ws';
import { getSelectedAdminDetails } from 'app/api-ws/admin-ws';
import AgencyProfile from './agencyProfile';

const ProfilePage = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState(user);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const handleCloseDialog = () => {
    getUserDetails();
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    console.log('open');
    setOpenDialog(true);
  };
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const getUserDetails = () => {
    if (user?.role === 'AGENCY') {
      getAgencyById(user?._id)
        .then((res) => {
          setUserDetails(res.data.agencyData);
        })
        .catch((err) => {
          console.log(err);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    } else if (user?.role === 'ADMIN') {
      getSelectedAdminDetails(user?._id)
        .then((res) => {
          // console.log(res);
          setUserDetails(res.data.Data);
        })
        .catch((err) => {
          console.log(err);
          setSnackBarMessage('Error in retrieving Profile Details');
          setSnackBarType('error');
          showSnackBar();
        });
    }
  };

  if (user?.role === 'ADMIN') {
    return (
      <div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={snackBarType}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>
        <AdminProfile
          userDetails={userDetails}
          openDialog={openDialog}
          handleDialogClose={handleCloseDialog}

          handleDialogOpen={handleOpenDialog}
        />
      </div>
    );
  } else if (user?.role === 'AGENCY') {
    return (
      <AgencyProfile
        userDetails={userDetails}
        openDialog={openDialog}
        handleDialogClose={handleCloseDialog}
        handleOpenDialog={handleOpenDialog}
      />
    );
  } else {
    return <NotFound />;
  }
};

export default ProfilePage;
