import Typography from '@mui/material/Typography';
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, Snackbar, styled, useMediaQuery } from '@mui/material';
import { Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addAgency, updateAgencyDetails } from 'app/api-ws/agency-ws';
import useAuth from 'app/hooks/useAuth';

ValidatorForm.addValidationRule('isPhoneNumber', (value) => {
  const phoneRegex = /^[0-9]{10}$/;
  if (!phoneRegex.test(value)) {
    return false; // Validation fails
  }
  return true; // Validation passes
});

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px'
}));

const AgencyForm = ({ disabled, agency, profileImage, handleCloseDialog, setProfileImage }) => {
  // console.log(agency);

  const [formData, setFormData] = useState({
    agencyName: agency?.agencyName ?? '',
    agencyRegionalName: agency?.agencyRegionalName ?? '',
    firstName: agency?.owner?.firstName ?? '',
    lastName: agency?.owner?.lastName ?? '',
    email: agency?.email ?? '',
    phone: agency?.phone ?? '',
    buildingNo: agency?.address?.buildingNo ?? '',
    street: agency?.address?.street ?? '',
    locality: agency?.address?.locality ?? '',
    district: agency?.address?.district ?? '',
    state: agency?.address?.state || 'Karnataka',
    pincode: agency?.address?.pincode ?? '',
    ownerProfile: agency?.ownerProfile ?? [],
    regNumber: agency?.regNumber ?? '',
    designation: agency?.designation ?? '',
    npLogo: agency?.npLogo ?? [],
  });

  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  // const [ownerImage, setOwnerImage] = useState([]);
  const [ownerImagePreview, setOwnerImagePreview] = useState([]);
  const [npLogoPreview, setNpLogoPreview] = useState([]);

  // const [uploadnpLogoRequest, setUploadNpLogoRequest] = useState([])
  // const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useAuth()
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUpdated, setIsImageUpdated] = useState(false);


  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('agencyName', formData.agencyName);
      data.append('agencyRegionalName', formData.agencyRegionalName);
      data.append('owner', JSON.stringify({
        firstName: formData.firstName,
        lastName: formData.lastName
      }));
      data.append('lastName', formData.lastName);
      data.append('email', formData.email);
      data.append('phone', formData.phone);
      data.append('address', JSON.stringify({
        buildingNo: formData.buildingNo,
        street: formData.street,
        locality: formData.locality,
        district: formData.district,
        state: formData.state,
        pincode: formData.pincode
      }));
      data.append('ownerProfile', formData.ownerProfile[0]);
      data.append('regNumber', formData.regNumber);
      data.append('designation', formData.designation);
      data.append('npLogo', formData.npLogo[0]);
      data.append('role', 'AGENCY');
      data.append('refAdminId', user?._id);
      // data.append('password', '_');
      if (agency?._id) {
        data.append('_id', agency._id);
        data.append('isImageUpdated', isImageUpdated);
      }

      const res = agency?._id ? await updateAgencyDetails(data) : await addAgency(data);

      if (res.data.status === 'success') {
        setSnackBarMessage('Agency Added Successfully');
        setIsLoading(false);
        setSnackBarType('success');
        showSnackBar();
        handleCloseDialog();
      } else {
        setSnackBarMessage('Error in adding Agency');
        setSnackBarType('error');
        showSnackBar();
      }
    } catch (err) {
      setIsLoading(false);
      setSnackBarMessage(err?.response?.data?.message);
      setSnackBarType('error');
      showSnackBar();
    };
  };

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }


  function parseDataUrl(dataUrl, originalFilename) {
    const matches = dataUrl?.match(/^data:(.*?);base64,(.*)$/);

    if (!matches || matches.length !== 3) {
      throw new Error('Invalid Data URL format');
    }

    const [, type, data] = matches;
    const filename = originalFilename || `image.${type.split('/')[1]}`;

    return { filename, type, data };
  }



  const handleOwnerImageChange = (event) => {
    setError(null);
    const files = Array.from(event.target.files);

    // console.log('Number of files:', files.length);

    const maxFileSize = 1 * 1024 * 1024; // 2MB

    if (files && files.length > 0) {
      // const imageArray = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > maxFileSize) {
          setError(`File size exceeds 1MB: ${currentFile.name}`);
          return;
        }
      }
      setFormData({ ...formData, ownerProfile: files });

      const previewFiles = files.map(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result);
        });
      });

      Promise.all(previewFiles).then(previewImages => {
        setOwnerImagePreview(previewImages);
      });
    }
    setIsImageUpdated(true);
  };

  const handleNpImageChange = (event) => {
    setError(null);
    const files = Array.from(event.target.files);

    // console.log('Number of files:', files.length);

    const maxFileSize = 1 * 1024 * 1024; // 2MB


    if (files && files.length > 0) {
      // const imageArray = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > maxFileSize) {
          setError(`File size exceeds 1MB: ${currentFile.name}`);
          return;
        }
      }
      setFormData({ ...formData, npLogo: files });

      const previewFiles = files.map(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result);
        });
      });

      Promise.all(previewFiles).then(previewImages => {
        setNpLogoPreview(previewImages);
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      {isLoading ? <Backdrop style={{ zIndex: 1300, color: '#fff' }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop> : null}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Grid
        style={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: 'space-around'
        }}
      >
        <SimpleCard title={'Owner Profile'}>
          <Avatar
            alt="Onwer Profile"
            src={agency?.ownerProfile?.url ?? agency?.ownerProfile ?? ownerImagePreview?.[0]}
            style={{ width: '150px', height: '150px', margin: 'auto' }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="agency-owner"
            type="file"
            onChange={handleOwnerImageChange}
            name='ownerProfile'
          />
          <label htmlFor="agency-owner">
            <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
              Upload Image
            </Button>
          </label>
        </SimpleCard>

        <SimpleCard title="News Paper Logo">
          {' '}
          <Avatar
            alt="News Paper Logo"
            src={agency?.npLogo?.url ?? agency?.npLogo ?? npLogoPreview?.[0]}
            // src={`data:image/jpeg;base64,${npLogo ? npLogo[0]?.fileContent : ""}`}
            style={{ width: '150px', height: '150px', margin: 'auto' }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="paper-logo"
            type="file"
            onChange={handleNpImageChange}
            name='npLogo'
          />
          <label htmlFor="paper-logo">
            <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
              Upload Image
            </Button>
          </label>
        </SimpleCard>
      </Grid>

      <Box py="12px" />

      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="firstName"
              id="standard-basic"
              value={formData.firstName || ''}
              onChange={handleInputChange}
              errorMessages={['this field is required']}
              label="Owner Firstname"
              validators={['required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="lastName"
              label="Owner Lastname"
              onChange={handleInputChange}
              value={formData.lastName || ''}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="designation"
              label="Designation"
              onChange={handleInputChange}
              value={formData.designation || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="agencyName"
              label="News Paper English"
              onChange={handleInputChange}
              value={formData.agencyName || ''}
              validators={['required']}
              errorMessages={['this field is required']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="agencyRegionalName"
              label="News Paper In Kannada"
              onChange={handleInputChange}
              value={formData.agencyRegionalName || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="email"
              disabled={disabled}
              label="Email"
              value={formData.email || ''}
              onChange={handleInputChange}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="phone"
              label="Phone Number"
              value={formData.phone || ''}
              onChange={handleInputChange}
              validators={['required', 'isNumber', 'matchRegexp:^\\d{10}$']} // Validate for 10 digits
              errorMessages={[
                'This field is required',
                'Must be a number',
                'Phone number must be 10 digits'
              ]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="regNumber"
              label="Registration Number"
              value={formData.regNumber || ''}
              // onChange={(val) => {
              //   const alphanumericValue = val.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters
              //   setRegNumber(alphanumericValue);
              // }}
              onChange={handleInputChange}
              validators={['required']} // Validate for 10 alphanumeric characters
              errorMessages={[
                'Registration number field is required',
              ]}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Address Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="buildingNo"
              onChange={handleInputChange}
              label="Agency Name"
              value={formData.buildingNo || ' '}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="street"
              onChange={handleInputChange}
              label="Street"
              value={formData.street || ' '}
            />
          </Grid>
          <Grid item xs={6}>
            {' '}
            <TextField
              type="text"
              name="locality"
              onChange={handleInputChange}
              label="Locality"
              value={formData.locality || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="district"
              onChange={handleInputChange}
              label="District"
              value={formData.district || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="state"
              onChange={handleInputChange}
              label="State"
              value={formData.state || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="number"
              name="pincode"
              onChange={handleInputChange}
              label="Pincode"
              value={formData.pincode || ''}
              validators={['required', 'isNumber']}
            />
          </Grid>

          {/* <FormControlLabel
            control={<Checkbox />}
            label="I have read and agree to the terms of service."
          /> */}
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {agency ? 'Save Changes' : 'Add Agency'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AgencyForm;
