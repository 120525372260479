import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { KA, EN } from '.';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'kannada',
        resources: {
            english: {
                translation: EN,
            },
            kannada: {
                translation: KA
            }
        }
    });

export default i18n; 