import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useState } from 'react';
import { SimpleCard } from 'app/components';

import { Grid, Stack } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
}));

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
    '& .closeButton': {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

const DialogTitle = (props) => {
    const { children, onClose } = props;
    return (
        <DialogTitleRoot disableTypography>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitleRoot>
    );
};

const ContactDialog = ({ fetchContactDetails, contact }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        fetchContactDetails();
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                View Details
            </Button>

            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Contact Details
                </DialogTitle>
                <Container>
                    <Stack spacing={3}>
                        <SimpleCard>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>Name:</Typography>
                                    <Typography>{contact?.contactName}</Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>Email:</Typography>
                                    <Typography>{contact?.email}</Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography>Reason:</Typography>
                                    <Typography>{contact?.reason}</Typography>
                                </div>
                            </div></SimpleCard>
                    </Stack>
                </Container>
            </Dialog>
        </div>
    );
};

export default ContactDialog;
