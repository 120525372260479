import React, { useState } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AgencyDialog from './AgencyDialog';
import DeleteAgency from './DeleteAgency';

const StyledTable = styled(Table)({
  whiteSpace: 'pre',
  minWidth: 600,
  overflowX: 'auto'
});

const AgencyTable = ({ deleteAgencyDetails, fetchAgencyDetails, agencyList }) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Sr. No.</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Name</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Email</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Action</TableCell>
            <TableCell align={isXsScreen ? 'left' : 'center'}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agencyList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((agency, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}
              >
                <TableCell align={isXsScreen ? 'left' : 'center'}>{index + 1}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  {agency?.agencyName.replace(/^\w/, (c) => c.toUpperCase())}
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>{agency?.email}</TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <AgencyDialog fetchAgencyDetails={fetchAgencyDetails} agency={agency} />
                </TableCell>
                <TableCell align={isXsScreen ? 'left' : 'center'}>
                  <DeleteAgency
                    deleteAgencyDetails={deleteAgencyDetails}
                    fetchAgencyDetails={fetchAgencyDetails}
                    agency={agency}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={'Records per page'}
        count={agencyList.length}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default AgencyTable;
