import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Snackbar, styled, IconButton, Typography, Icon } from '@mui/material';
import { getNewsByAgencyId, removeNewsDetails, getAllNewsDetails } from 'app/api-ws/news-ws';
import useAuth from 'app/hooks/useAuth';
import { SimpleCard } from 'app/components';
import NewsTable from './NewsTable';
import NewsForm from './NewsForm';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import MuiDialogTitle from '@mui/material/DialogTitle';


const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  '& .closeButton': {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className="closeButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useAuth()
  console.log(user?.role)
  // console.log(user)
  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchNewsDetails(user?._id);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      fetchAllNewsDetails()
    } else {
      fetchNewsDetails(user._id);
    }
  }, []);

  const deleteNewsDetails = (newsId) => {
    setIsLoading(true)
    removeNewsDetails(newsId)
      .then((res) => {
        fetchNewsDetails(user?._id);
        setIsLoading(false);

      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in removing News');
        setSnackBarType('error', err);
        showSnackBar();
      });
  };
  const fetchNewsDetails = (agencyId) => {
    getNewsByAgencyId(agencyId)
      .then((res) => {
        setNewsList(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in fetching News');
        setSnackBarType('error', err);
        showSnackBar();
      });
  };
  const fetchAllNewsDetails = () => {
    getAllNewsDetails()
      .then((res) => {
        setNewsList(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in fetching News');
        setSnackBarType('error', err);
        showSnackBar();
      });
  };
  // console.log(newsList)
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1)
  }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
  }));

  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon> Add News
        </StyledButton>

        <Dialog onClose={() => setOpenDialog(false)} aria-labelledby="customized-dialog-title" open={openDialog}>
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
            News Form</DialogTitle>
          <DialogContent>
            <NewsForm handleCloseDialog={handleCloseDialog} />
          </DialogContent>
        </Dialog>

        <SimpleCard title="News Table">
          <NewsTable
            deleteNewsDetails={deleteNewsDetails}
            fetchNewsDetails={fetchNewsDetails}
            newsList={newsList}
          />
        </SimpleCard>
      </Container>
    </div>
  );
};

export default NewsList;
