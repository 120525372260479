import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Avatar,
  Grid,
  Box,
  Paper,
  Divider,
  getFormControlLabelUtilityClasses
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import { styled } from '@mui/system';
import AdminForm from '../admin/AdminForm';

const useStyles = styled((theme) => ({
  mainCard: {
    borderRadius: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    width: '100%'
  },
  editBtn: { alignSelf: 'center' },

  [theme.breakpoints.down('sm')]: {
    mainCard: {
      flexDirection: 'column', // Display image above text
      width: '90%',
      margin: '5px auto'
    },
    cardContent: {
      flexDirection: 'column'
      // Display the image above the text
    },
    box: {
      flexDirection: 'column'
    },
    paper: {
      padding: '1rem'
    },
    editBtn: {
      justifyContent: 'center'
    },
    docName: {
      marginTop: '100px'
    }
  }
}));

const AdminProfile = ({ userDetails, openDialog, handleDialogClose, handleDialogOpen }) => {
  const [adminName, setAdminName] = useState(userDetails?.adminName);
  const [email, setEmail] = useState(userDetails?.email);
  const [profileImage, setProfileImage] = useState(userDetails?.adminProfile);
  const [phone, setPhone] = useState(userDetails?.phone);
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        height: '100vh',

        width: '100%'
      }}
    >
      <Grid item xs={12} sm={8}>
        <Card className={classes.mainCard} variant="outlined">
          <Typography
            variant="h3"
            sx={{ marginTop: '20px', textAlign: 'center', fontFamily: 'Geogria' }}
          >
            PROFILE
          </Typography>
          <CardContent className={classes.cardContent}>
            <Box
              className={classes.box}
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  flex: 1,
                  borderRadius: '16px',
                  marginRight: '1rem',
                  justifyContent: 'Center',
                  alignContent: 'center',
                  // backgroundColor: 'red',
                  maxHeight: '350px',
                  maxWidth: '400px'
                }}
              >
                <CardContent style={{}}>
                  <Avatar
                    className={classes.img}
                    alt="Admin"
                    src={`data:image/jpeg;base64,${profileImage}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '16px',
                      justifyContent: 'center',
                      display: 'flex',
                      objectFit: 'contain'
                    }}
                  />
                </CardContent>
              </Card>
              <Paper
                className={classes.paper}
                sx={{
                  color: 'black',
                  padding: '2rem',
                  flex: 1,
                  borderRadius: '16px',
                  elevation: '0',
                  boxShadow: 'none'
                }}
              >
                <Typography
                  className="docName"
                  variant="h4"
                  style={{ marginTop: '-20px', marginBottom: '40px' }}
                >
                  {adminName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    margin: '4px',
                    alignItems: 'center',
                    marginTop: '1rem'
                  }}
                >
                  <EmailIcon style={{ marginRight: '8px' }} /> {email}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    margin: '4px',
                    alignItems: 'center',
                    marginTop: '1rem'
                  }}
                >
                  <PhoneIcon style={{ marginRight: '8px' }} /> {phone}
                </Typography>
                <Divider style={{ margin: '8px', width: '80%' }} />

                <div
                  style={{
                    marginTop: '1rem',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    className={classes.editBtn}
                    variant="contained"
                    color="primary"
                    justifyContent="cntre"
                    onClick={handleDialogOpen}
                  >
                    Edit Profile
                  </Button>
                </div>
              </Paper>
              <Dialog onClose={handleDialogClose} open={openDialog}>
                <DialogTitle>Admin Form</DialogTitle>
                <DialogContent>
                  <AdminForm
                    setProfileImage={setProfileImage}
                    profileImage={profileImage}
                    admin={userDetails}
                    handleCloseDialog={handleDialogClose}
                  />
                </DialogContent>
              </Dialog>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminProfile;
