import Typography from '@mui/material/Typography';
import { Avatar, Alert, Button, Grid, Snackbar, styled } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addAdmin, updateAdminDetails } from 'app/api-ws/admin-ws';

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px'
}));

const AdminForm = ({ admin, handleCloseDialog }) => {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [adminName, setAdminName] = useState(admin?.adminName);
  const [email, setEmail] = useState(admin?.email);
  const [profileImage, setProfileImage] = useState(admin?.adminProfile);
  const [phone, setPhone] = useState(admin?.phone);

  const [preview, setPreview] = useState('');
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  // var adminRequest = {};
  // adminRequest = {
  //   adminName: adminName,
  //   email: email,
  //   phone: phone,
  //   password: 'Admin@123',
  //   role: 'ADMIN'
  // };
  var updateAdminRequest = {};
  updateAdminRequest = {
    _id: admin?._id,
    adminName: adminName,
    email: email,
    adminProfile: profileImage,
    phone: phone
  };
  const handleSubmit = async (event) => {
    try {
      const data = new FormData();
      data.append('adminName', adminName);
      data.append('email', email);
      data.append('phone', phone);
      data.append('adminProfile', profileImage);
      if (admin?._id) {
        // While updating admin
        data.append('_id', admin._id);
        data.append('isImageUpdated', isImageUpdated);
      } else {
        // While adding admin
        data.append('password', 'Admin@123');
        data.append('role', 'ADMIN');
      }

      const res = admin?._id ? await updateAdminDetails(data) : await addAdmin(data);
      // console.log(res);
      if (res.data.status === 'success') {
        setSnackBarMessage('Admin Added Successfully');
        setSnackBarType('success');
        showSnackBar();
        handleCloseDialog();
      } else {
        setSnackBarMessage('Error in adding Admin');
        setSnackBarType('error');
        showSnackBar();
      }
    } catch (error) {
      console.log(error);
      setSnackBarMessage('Error in adding Admin');
      setSnackBarType('error');
      showSnackBar();
    }
    console.log('submitted');
  };
  // const updateAdminSubmit = (event) => {
  //   updateAdminDetails(updateAdminRequest)
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.data.status === 'success') {
  //         setSnackBarMessage('Admin Added Successfully');
  //         setSnackBarType('success');
  //         showSnackBar();
  //         handleCloseDialog();
  //       } else {
  //         setSnackBarMessage('Error in adding Admin');
  //         setSnackBarType('error');
  //         showSnackBar();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSnackBarMessage('Error in adding Admin');
  //       setSnackBarType('error');
  //       showSnackBar();
  //     });
  //   console.log('submitted');
  // };

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      setProfileImage(file);

      const previewFile = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result); // Resolve with the data URL
          };
          reader.readAsDataURL(file);
        });
      };

      previewFile(file).then(dataUrl => {
        setPreview(dataUrl);
      });
    }
    setIsImageUpdated(true);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        <Avatar
          alt="Agency"
          src={admin?.adminProfile?.url ?? admin?.adminProfile ?? preview}
          style={{ width: '150px', height: '150px', margin: 'auto' }}
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
            Upload Logo
          </Button>
        </label>
      </SimpleCard>
      {/* <Box py="12px" /> */}

      <ValidatorForm onSubmit={handleSubmit} onError={() => null}>
        <Typography variant="h6" gutterBottom>
          Personal Details
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="AdminName"
              id="standard-basic"
              value={adminName || ''}
              onChange={(val) => setAdminName(val.target.value)}
              errorMessages={['this field is required']}
              label="Adminname (Min length 4, Max length 20)"
              validators={['required', 'minStringLength: 4', 'maxStringLength: 20']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text" // Use "text" type for the phone number
              name="phoneNumber"
              label="Phone Number"
              value={phone || ''}
              onChange={(val) => {
                // Ensure that the entered value is numeric
                const numericValue = val.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                setPhone(numericValue);
              }}
              validators={['required', 'isNumber', 'matchRegexp:^\\d{10}$']} // Validate for 10 digits
              errorMessages={[
                'This field is required',
                'Must be a number',
                'Phone number must be 10 digits'
              ]}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="email"
              label="Email"
              value={email || ''}
              onChange={(val) => setEmail(val.target.value)}
              validators={['required', 'isEmail']}
              errorMessages={['this field is required', 'email is not valid']}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {admin ? 'Save Changes' : 'Add Admin'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AdminForm;
