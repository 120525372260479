import Typography from '@mui/material/Typography';
import { Alert, Autocomplete, Backdrop, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, styled } from '@mui/material';
import { Avatar } from '@mui/material';
import { SimpleCard } from 'app/components';
import { Span } from 'app/components/Typography';
import { useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { addNews, updateNewsDetails, uploadNewsImages } from 'app/api-ws/news-ws';
import useAuth from 'app/hooks/useAuth';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DeleteIcon from '@mui/icons-material/Delete';
ValidatorForm.addValidationRule('isPhoneNumber', (value) => {
  const phoneRegex = /^[0-9]{10}$/;
  if (!phoneRegex.test(value)) {
    return false; // Validation fails
  }
  return true; // Validation passes
});

const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px'
}));

const NewsForm = ({ news, handleCloseDialog }) => {
  const { user } = useAuth()
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(news?.title);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [description, setDescription] = useState(news?.description);
  const [summary, setSummary] = useState(news?.summary);
  const [createdDate, setCreatedDate] = useState(moment(news?.createdDate).format('YYYY-MM-DD'));
  const [reporterName, setReporterName] = useState(news?.reporterName);
  const [imageSource, setImageSource] = useState(news?.imageSource);
  const [refAgencyId, setRefAgencyId] = useState(news?.refAgencyId || user?._id);
  const [newsRegion, setNewsRegion] = useState(news?.newsRegion);
  const [refAgencyName, setRefAgencyName] = useState(news?.refAgencyName || user?.agencyName);
  const [newsImage, setNewsImage] = useState(news?.newsImage);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [selectedNewsTypes, setSelectedNewsTypes] = useState(news?.newsType ? news.newsType : null);
  const [newsReference, setNewsReference] = useState('');
  const [addedNews, setAddedNews] = useState()
  const [images, setImages] = useState([]);
  const [newsDomain, setNewsDomain] = useState(news?.newsDomain)
  const [uploadImagesRequest, setUploadImagesRequest] = useState([])
  const [newsTypeError, setNewsTypeError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  var newsRequest = {};
  const international = [
    { "label": "Afghanistan" },
    { "label": "Armenia" },
    { "label": "Azerbaijan" },
    { "label": "Bahrain" },
    { "label": "Bangladesh" },
    { "label": "Bhutan" },
    { "label": "Brunei" },
    { "label": "Cambodia" },
    { "label": "China" },
    { "label": "Cyprus" },
    { "label": "Georgia" },
    { "label": "India" },
    { "label": "Indonesia" },
    { "label": "Iran" },
    { "label": "Iraq" },
    { "label": "Israel" },
    { "label": "Japan" },
    { "label": "Jordan" },
    { "label": "Kazakhstan" },
    { "label": "Kuwait" },
    { "label": "Kyrgyzstan" },
    { "label": "Laos" },
    { "label": "Lebanon" },
    { "label": "Malaysia" },
    { "label": "Maldives" },
    { "label": "Mongolia" },
    { "label": "Myanmar (Burma)" },
    { "label": "Nepal" },
    { "label": "North Korea" },
    { "label": "Oman" },
    { "label": "Pakistan" },
    { "label": "Palestine" },
    { "label": "Philippines" },
    { "label": "Qatar" },
    { "label": "Saudi Arabia" },
    { "label": "Singapore" },
    { "label": "South Korea" },
    { "label": "Sri Lanka" },
    { "label": "Syria" },
    { "label": "Taiwan" },
    { "label": "Tajikistan" },
    { "label": "Thailand" },
    { "label": "Timor-Leste" },
    { "label": "Turkey" },
    { "label": "Turkmenistan" },
    { "label": "United Arab Emirates" },
    { "label": "Uzbekistan" },
    { "label": "Vietnam" },
    { "label": "Yemen" }
  ]
  const national =
    [
      { "label": "Andhra Pradesh" },
      { "label": "Arunachal Pradesh" },
      { "label": "Assam" },
      { "label": "Bihar" },
      { "label": "Chhattisgarh" },
      { "label": "Goa" },
      { "label": "Gujarat" },
      { "label": "Haryana" },
      { "label": "Himachal Pradesh" },
      { "label": "Jharkhand" },
      { "label": "Karnataka" },
      { "label": "Kerala" },
      { "label": "Madhya Pradesh" },
      { "label": "Maharashtra" },
      { "label": "Manipur" },
      { "label": "Meghalaya" },
      { "label": "Mizoram" },
      { "label": "Nagaland" },
      { "label": "Odisha" },
      { "label": "Punjab" },
      { "label": "Rajasthan" },
      { "label": "Sikkim" },
      { "label": "Tamil Nadu" },
      { "label": "Telangana" },
      { "label": "Tripura" },
      { "label": "Uttar Pradesh" },
      { "label": "Uttarakhand" },
      { "label": "West Bengal" },
      { "label": "Andaman and Nicobar Islands" },
      { "label": "Chandigarh" },
      { "label": "Dadra and Nagar Haveli and Daman and Diu" },
      { "label": "Delhi" },
      { "label": "Lakshadweep" },
      { "label": "Puducherry" }
    ]
  const regional = [
    { "label": "Bagalkot" },
    { "label": "Ballari" },
    { "label": "Belagavi" },
    { "label": "Bengaluru Urban" },
    { "label": "Bengaluru Rural" },
    { "label": "Bidar" },
    { "label": "Chamarajanagar" },
    { "label": "Chikkaballapur" },
    { "label": "Chikkamagaluru" },
    { "label": "Chitradurga" },
    { "label": "Dakshina Kannada" },
    { "label": "Davanagere" },
    { "label": "Dharwad" },
    { "label": "Gadag" },
    { "label": "Hassan" },
    { "label": "Haveri" },
    { "label": "Kalaburagi" },
    { "label": "Kodagu" },
    { "label": "Kolar" },
    { "label": "Koppal" },
    { "label": "Mandya" },
    { "label": "Mysuru" },
    { "label": "Raichur" },
    { "label": "Ramanagara" },
    { "label": "Shivamogga" },
    { "label": "Tumakuru" },
    { "label": "Udupi" },
    { "label": "Uttara Kannada" },
    { "label": "Vijayapura" },
    { "label": "Vijayanagara" },
    { "label": "Yadgir" }
  ]


  newsRequest = {
    title: title,
    description: description,
    summary: summary,
    newsImage: newsImage,
    createdDate: createdDate,
    reporterName: reporterName,
    imageSource: imageSource,
    newsType: selectedNewsTypes,
    refAgencyId: refAgencyId,
    newsRegion: newsRegion,
    newsDomain: newsDomain,
    refAgencyName: refAgencyName,
    newsReference: newsReference,
    refNpLogo: user?.npLogo,
  };

  const newsTypeOptions = [
    { value: 'Political', label: 'Political' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Entertainment', label: 'Entertainment' },
    { value: 'Business', label: 'Business' },
  ];

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms);
  };

  const addhandleSubmit = async (event) => {
    try {
      setIsLoading(true);
      const newsData = new FormData();
      newsData.append('title', title);
      newsData.append('description', description);
      newsData.append('summary', summary);
      // newsData.append('newsImage', newsImage);
      newsData.append('createdDate', createdDate);
      newsData.append('reporterName', reporterName);
      newsData.append('imageSource', imageSource);
      newsData.append('newsType', JSON.stringify(selectedNewsTypes));
      newsData.append('refAgencyId', refAgencyId);
      newsData.append('newsRegion', newsRegion);
      newsData.append('newsDomain', newsDomain);
      newsData.append('refAgencyName', refAgencyName);
      newsData.append('newsReference', newsReference);
      newsData.append('refNpLogo', user?.npLogo);
      if (news?._id) {
        newsData.append('_id', news._id);
        newsData.append('isImageUpdated', isImageUpdated);
      }

      // newsData.append('refAgencyId', refAgencyId);
      // formData.append('refNewsId', res.data.data._id);
      for (let i = 0; i < uploadImagesRequest.length; i++) {
        newsData.append('newsImage', uploadImagesRequest[i]);
      }
      const res = news?._id ? await updateNewsDetails(newsData) : await addNews(newsData);
      if (res.data.status === 'success') {
        setAddedNews(res.data);
        setSnackBarMessage('News Added Successfully');
        setSnackBarType('success');
        showSnackBar();
      } else {
        setSnackBarMessage('Error in adding News');
        setSnackBarType('error');
        showSnackBar();
      }
    } catch (err) {
      console.error(err);
      setSnackBarMessage('Error in adding News');
      setSnackBarType('error');
      showSnackBar();
    } finally {
      setIsLoading(false);
      handleCloseDialog();
    };
  };
  function parseDataUrl(dataUrl, originalFilename) {
    const matches = dataUrl?.match(/^data:(.*?);base64,(.*)$/);

    if (!matches || matches.length !== 3) {
      throw new Error('Invalid Data URL format');
    }

    const [, type, data] = matches;
    const filename = originalFilename || `image.${type.split('/')[1]}`;

    return { filename, type, data };
  }

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleImageChange = (event) => {
    setError(null);
    const files = Array.from(event.target.files);

    // console.log('Number of files:', files.length);

    const maxFileSize = 1 * 1024 * 1024; // 2MB

    if (files && files.length > 0) {
      // const imageArray = [];

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];

        if (currentFile.size > maxFileSize) {
          setError(`File size exceeds 1MB: ${currentFile.name}`);
          return;
        }
      }
      setUploadImagesRequest(files);

      const previewFiles = files.map(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result);
        });
      });

      Promise.all(previewFiles).then(previewImages => {
        setPreviews(previewImages);
      });
    }
    setIsImageUpdated(true);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };


  return (
    <div>
      {isLoading ? <Backdrop style={{ zIndex: 1300, color: '#fff' }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop> : null}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <SimpleCard>
        {' '}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {news?.newsImage?.length > 0 && (
            <div>
              <h3>News Images</h3>
              <Grid container spacing={2}>
                {news?.newsImage?.map((image, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div style={{ position: 'relative' }}>
                      <a href={image.url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={image.url}
                          alt={`${image.key?.split('/').pop()}`}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '300px',
                            marginBottom: '10px',
                            borderRadius: '10px',
                            border: '2px solid #333',
                            padding: '5px',
                            height: '200px',
                          }}
                        />
                      </a>

                      {hoveredIndex === index && (
                        <IconButton
                          // onClick={() => handleDeleteImage(index)} // You need to implement handleDeleteImage function
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            background: 'rgba(0, 0, 0, 0.15)',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}

                      {/* Delete
                            </button> */}
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {previews?.length > 0 && (
            <div>
              <h3>Preview:</h3>
              <Grid container spacing={2}>
                {previews?.map((preview, index) => (
                  <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                    <img
                      src={preview}
                      // src={`data:image/jpeg;base64,${newsImage}`}
                      alt={`Uploaded ${index + 1}`}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '300px',
                        marginBottom: '10px',
                        borderRadius: '10px',
                        border: '2px solid #333',
                        padding: '5px',
                        height: '200px',
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleImageChange}
            name='newsImage'
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span" style={{ marginTop: '20px' }}>
              Upload Image
            </Button>
          </label>
          {error ? <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert> : null}


        </div>

      </SimpleCard>
      <Box py="12px" />

      <ValidatorForm onSubmit={addhandleSubmit} onError={() => null}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="title"
              id="standard-basic"
              value={title || ''}
              onChange={(val) => {
                setTitle(val.target.value);
              }}
              errorMessages={['Required! Please fill out this field.']}
              label="Title"
              validators={['required']}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="date"
              name="createdDate"
              value={createdDate || ''}
              onChange={(val) => {
                setCreatedDate(val.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="reporterName"
              label="Reporter Name"
              onChange={(event) => {
                const inputValue = event.target.value;
                const filteredValue = inputValue.replace(/[^a-zA-Z\s]/g, '');
                setReporterName(filteredValue);
              }}
              value={reporterName || ''}
              validators={['required']}
              errorMessages={['Required! Please fill out this field.']}
            />

          </Grid>

          <Grid item xs={6}>
            <TextField
              type="text"
              name="imageSource"
              label="Image Source"
              value={imageSource || ''}
              onChange={(val) => {
                const numericValue = val.target.value; // Remove non-numeric characters
                setImageSource(numericValue);
              }}
            // validators={['required']} // Validate for 10 digits
            // errorMessages={['Required! Please fill out this field.']}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              required
              disablePortal
              options={newsTypeOptions}
              value={selectedNewsTypes ?? ''}
              onChange={(_, newValues) => {
                setSelectedNewsTypes(newValues);
                setNewsTypeError(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  name="newsType"
                  label="News Type"
                  fullWidth
                  error={newsTypeError && (!selectedNewsTypes || selectedNewsTypes.length === 0)} // Adjust error condition
                  helperText={newsTypeError && (!selectedNewsTypes || selectedNewsTypes.length === 0) ? 'Required! Please fill out this field.' : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">News Scope</InputLabel>
              <Select
                required
                value={newsRegion}
                label="News Region"
                onChange={(e) => {
                  setNewsRegion(e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "start",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "start",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={'INTERNATIONAL'}>International</MenuItem>
                <MenuItem value={'NATIONAL'}>National</MenuItem>
                <MenuItem value={'REGIONAL'}>Regional</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              required
              disablePortal
              disableClearable='true'
              options={newsRegion === 'NATIONAL' ? national : newsRegion === 'INTERNATIONAL' ? international : newsRegion === 'REGIONAL' ? regional : []}
              value={newsDomain || ''}
              // getOptionLabel={(option) => option}
              onChange={(event, val) => setNewsDomain(val.label)}
              renderInput={(params) => <TextField {...params} label="News Region" />}
            />
            {/* <TextField
              type="text"
              name="newsRegion"
              label="News Region"
              value={newsRegion || ''}
              onChange={(val) => {
                const numericValue = val.target.value; // Remove non-numeric characters
                setNewsRegion(numericValue);
              }}
              validators={['required']} // Validate for 10 digits
              errorMessages={['This field is required']}
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled="true"
              type="text"
              name="refAgencyName"
              label="Publication Name"
              value={refAgencyName || user?.role}
              onChange={(val) => {
                const numericValue = val.target.value; // Remove non-numeric characters
                setRefAgencyName(numericValue);
              }}
              validators={['required']} // Validate for 10 digits
              errorMessages={['Required! Please fill out this field.']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="newsReference"
              label="News Reference"
              value={newsReference || ''}
              onChange={(val) => {
                setNewsReference(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactQuill
              style={{ height: '200px' }}
              value={description || ''}
              onChange={(value) => {
                setDescription(value);
              }}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['link'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet',
                'link'
              ]}
              placeholder="Please add News details here..."
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '60px' }}>

          </Grid>
          <Grid item xs={12}>
            <ReactQuill
              style={{ height: '200px' }}
              value={summary || ''}
              onChange={(value) => {
                setSummary(value);
              }}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['link'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet',
                'link'
              ]}
              placeholder="Please add News Summary here..."
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '80px' }}>

          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Checkbox
            checked={acceptTerms}
            onChange={handleAcceptTermsChange}
            name="acceptTerms"
            color="primary"
          />
          <Typography style={{ color: 'blue' }} component={Link} to='/terms' target='_blank'>Agreed to Terms and Conditions</Typography>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button disabled={!acceptTerms} color="primary" variant="contained" type="submit">
            <Span sx={{ pl: 1, textTransform: 'capitalize' }}>
              {news ? 'Save Changes' : 'Add News'}
            </Span>
          </Button>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default NewsForm;
