import { SimpleCard } from 'app/components';
import {
  Button,
  DialogTitle,
  DialogContent,
  Icon,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { getAllAgencyDetails, removeAgencyById } from 'app/api-ws/agency-ws';
import AgencyTable from './AgencyTable';
import AgencyForm from './AgencyForm';

const AgencyList = () => {
  const [agencyList, setAgencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);

  function showSnackBar() {
    setOpen(true);
  }
  function handleClose(_, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }
  const handleCloseDialog = () => {
    fetchAgencyDetails();
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchAgencyDetails();
  }, []);

  const deleteAgencyDetails = (agencyId) => {
    removeAgencyById(agencyId)
      .then((res) => {
        fetchAgencyDetails();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(true);
        setSnackBarMessage('Error in Removing Agency');
        setSnackBarType('error', err);
        showSnackBar();
      });
  };
  const fetchAgencyDetails = () => {
    console.log('fetch');
    getAllAgencyDetails()
      .then((res) => {
        setAgencyList(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSnackBarMessage('Error in fetching Agency');
        setSnackBarType('error', err);
        showSnackBar();
      });
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1)
  }));
  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
  }));

  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snackBarType}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Container>
        <StyledButton variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          <Icon className="icon">group_add</Icon> Add Agency
        </StyledButton>

        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
          <DialogTitle>Agency Form</DialogTitle>
          <DialogContent>
            <AgencyForm handleCloseDialog={handleCloseDialog} />
          </DialogContent>
        </Dialog>

        <SimpleCard title="AGENCY DETAILS">
          <AgencyTable
            deleteAgencyDetails={deleteAgencyDetails}
            fetchAgencyDetails={fetchAgencyDetails}
            agencyList={agencyList}
          />
        </SimpleCard>
      </Container>
    </div>
  );
};

export default AgencyList;
