import { HttpMethod } from 'app/utils/network';
import { API } from 'app/utils/request';


export const getAllContactDetails = () => {
    const urlPath = '/contact/getAllContactDetails';
    return API.request({
        method: HttpMethod.GET,
        urlPath: urlPath
    });
};

export const removeContactById = (contactId) => {
    const urlPath = `/contact/removeContact/${contactId}`;
    return API.request({
        method: HttpMethod.DELETE,
        urlPath: urlPath
    });
};
